import { usePrevPageRouter } from '@graphcommerce/framer-next-pages'
import { i18n } from '@lingui/core'
import { IconSvg } from '@graphcommerce/next-ui/IconSvg'
import IconArrowLeft from '../../components/Common/Icons/ArrowLeft/arrow-left-icon.svg'
import { useRouter } from 'next/router'
import { Fab } from '@mui/material'

const OverlayHeaderBackBtn = ({ icon, onBack }: { icon?: any; onBack?: () => void }) => {
  const prevRouter = usePrevPageRouter()
  const router = useRouter()
  const href = router.asPath.split('?')[0]

  return (
    <Fab
      color='inherit'
      onClick={async () => {
        if (onBack) {
          return onBack()
        }
        if (!prevRouter || href === prevRouter?.asPath) {
          return router.push('/')
        }
        return router.back()
      }}
      sx={{ boxShadow: 'none' }}
      size='responsive'
      aria-label={i18n._(/* i18n */ 'Close')}
    >
      {icon ? (
        icon
      ) : (
        <IconSvg
          sx={{
            fontSize: '24px',
          }}
          src={IconArrowLeft}
          size='inherit'
          aria-hidden
        />
      )}
    </Fab>
  )
}

export default OverlayHeaderBackBtn
