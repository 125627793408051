import React from 'react'
import { PluginProps } from '@graphcommerce/next-config'
import { LayoutHeaderContentProps } from '@graphcommerce/next-ui/Layout/components/LayoutHeaderContent'
import { useRouter } from 'next/router'
import { LayoutHeaderCloseNavigateAccount } from '../../components/Layout/LayoutHeaderCloseNavigateAccount'
import { FILTER_SELECT_PRODUCT_URL } from '../../common/constants/account-link'

export const component = 'LayoutHeaderContent'
export const exported = '@graphcommerce/next-ui/Layout/components/LayoutHeaderContent'

// FL-149: Remove left button (back)
function LayoutHeaderContentPlugin(props: PluginProps<LayoutHeaderContentProps>) {
  const { Prev, right, ...rest } = props
  const router = useRouter()

  return (
    <Prev
      {...rest}
      // right={
      //   [FILTER_SELECT_PRODUCT_URL].includes(router.pathname) ? (
      //     <LayoutHeaderCloseNavigateAccount />
      //   ) : (
      //     right
      //   )
      // }
      right={right}
    />
  )
}

export const Plugin = LayoutHeaderContentPlugin
