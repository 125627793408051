import { Trans } from '@lingui/react'
import { Typography } from '@mui/material'
import React from 'react'

interface ProductSkuProps {
  sku: string
  fontSize?: number[]
}

const ProductSku: React.FC<ProductSkuProps> = ({ sku, fontSize = [18, 16] }) => {
  const [desktopFontSize, mobileFontSize] = fontSize
  return (
    <React.Fragment>
      <Typography
        variant='body1'
        sx={(theme) => ({
          color: theme.palette.filtoria.grey.darker,
          fontSize: { xs: mobileFontSize, md: desktopFontSize },
        })}
      >
        <Trans id='Filtoria varenummer' />:{' '}
        <Typography
          component='span'
          noWrap
          sx={{ fontSize: { xs: mobileFontSize, md: desktopFontSize } }}
        >
          {sku}
        </Typography>
      </Typography>
    </React.Fragment>
  )
}

export default ProductSku
