{
  /*
Page: /account/select-product
*/
}
import { magentoMenuToNavigationMain } from '../../utils/magentoMenuToNavigationMain'
import {
  LayoutDefaultProps,
  NavigationProvider,
  useNavigationSelection,
  useMemoDeep,
} from '@graphcommerce/next-ui'
import { Container, SxProps, Theme } from '@mui/material'
import { LayoutQuery } from './Layout.gql'
import { NavigationOverlayByNP } from './Navigation/NavigationOverlayByNP'
import { useRouter } from 'next/router'

export type LayoutNavigationProps = LayoutQuery &
  Omit<LayoutDefaultProps, 'footer' | 'header' | 'cartFab' | 'menuFab'>

export function LayoutOverlayNavigation(props: LayoutNavigationProps) {
  const { menu, children, ...uiProps } = props

  const selection = useNavigationSelection()
  const router = useRouter()

  const overlaySx: SxProps<Theme> = (theme) => ({
    '& .NavigationItem-li:not(.column1)': {
      '& .NavigationItem-item': {
        borderBottom: '1px solid ' + theme.palette.filtoria.grey.lighter,
      },
    },
    '& .NavigationItem-li': {
      '& .MuiListItemButton-root:not(.MenuFabSecondaryItem-root) .IconSvg-root': {
        color: theme.palette.filtoria.primary.dark,
      },
    },
  })

  const handleClickClose = () => {
    router.push('/account')
  }

  return (
    <>
      <NavigationProvider
        selection={selection}
        items={useMemoDeep(() => [...magentoMenuToNavigationMain(menu, true)], [menu])}
      >
        <Container className='product-category-menu-overlay-wrap'>
          <NavigationOverlayByNP
            stretchColumns={false}
            variantSm='bottom'
            sizeSm='full'
            justifySm='start'
            itemWidthSm='100vw'
            variantMd={'right'}
            sizeMd='full'
            justifyMd='start'
            itemWidthMd='304px'
            mouseEvent='click'
            itemPadding='md'
            isParent={false}
            sx={overlaySx}
            onCloseParent={handleClickClose}
          />
        </Container>
      </NavigationProvider>

      {children}
    </>
  )
}
