import React, { useEffect } from 'react'
import { PageOptions } from '@graphcommerce/framer-next-pages'
import { LayoutOverlayProps } from '../../components'
import { GetStaticProps } from '@graphcommerce/next-ui'
import { LayoutDocument } from '../../components/Layout/Layout.gql'
import { graphqlSharedClient, graphqlSsrClient } from '../../lib/graphql/graphqlSsrClient'
import { StoreConfigDocument } from '@graphcommerce/magento-store'
import { LayoutOverlayNavigation } from '../../components/Layout/LayoutOverlayNavigation'

type Props = Record<string, unknown>
type GetPageStaticProps = GetStaticProps<LayoutOverlayProps, Props>

export const CUSTOM_PARAM = 'addFilterOnly'
const SelectProduct = () => {
  useEffect(() => {
    document.getElementsByName('search')[0]?.blur()
  }, [])
  return <React.Fragment></React.Fragment>
}

SelectProduct.pageOptions = {
  // Layout: LayoutNavigation,
  overlayGroup: 'account',
  sharedKey: () => 'account',
  Layout: LayoutOverlayNavigation,
  layoutProps: {
    up: '/account',
    variantMd: 'right',
    variantSm: 'bottom',
    onClose: () => {
      // console.log('dđ')
    },
  },
} as PageOptions

export const getStaticProps: GetPageStaticProps = async ({ locale }) => {
  const client = graphqlSharedClient(locale)
  const staticClient = graphqlSsrClient(locale)
  const config = client.query({ query: StoreConfigDocument })
  const layout = staticClient.query({ query: LayoutDocument })

  const data = await layout
  function updateUrl(item: any) {
    return {
      ...item,
      url_path: 'add-filter/' + item.url_path + '?' + CUSTOM_PARAM,
      children:
        item?.children && item?.children?.length
          ? item?.children?.map((child: any) => updateUrl(child))
          : null,
    }
  }
  return {
    props: {
      menu: {
        items: data.data.menu?.items?.map((item) => updateUrl(item)),
        [CUSTOM_PARAM]: true,
      },
      apolloState: await config.then(() => client.cache.extract()),
      up: { href: '/account', title: 'Account' },
      classes: 'select-product-overlay w-md-800',
    },
  }
}
export default SelectProduct
