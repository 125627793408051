import { ProductListItemProps } from '@graphcommerce/magento-product/components/ProductListItem/ProductListItem'
// import { ProductListPrice } from '@graphcommerce/magento-product/components/ProductListPrice/ProductListPrice'
import { useProductLink } from '@graphcommerce/magento-product/hooks/useProductLink'
import { PluginProps } from '@graphcommerce/next-config'
import { breakpointVal } from '@graphcommerce/next-ui/Styles/breakpointVal'
import { extendableComponent } from '@graphcommerce/next-ui/Styles/extendableComponent'
import { responsiveVal } from '@graphcommerce/next-ui/Styles/responsiveVal'
import { useNumberFormat } from '@graphcommerce/next-ui/hooks/useNumberFormat'
import { Trans } from '@lingui/react'
import Box from '@mui/material/Box/Box'
import Typography from '@mui/material/Typography/Typography'
import styled from '@mui/material/styles/styled'
import useEventCallback from '@mui/material/utils/useEventCallback'
import { Image } from '@graphcommerce/image'
import { Button, Stack } from '@mui/material'
import { IconSvg, iconChevronRight } from '@graphcommerce/next-ui'
import { useRouter, usePathname } from 'next/navigation'
import ProductSku from '../../components/Catalog/Product/ProductSku'

export const component = 'ProductListItem'
export const exported = '@graphcommerce/magento-product/components/ProductListItem/ProductListItem'

const { classes } = extendableComponent('ProductListItem', [
  'root',
  'item',
  'title',
  'titleContainer',
  'subtitle',
  'price',
  'overlayItems',
  'topLeft',
  'topRight',
  'bottomLeft',
  'bottomRight',
  'imageContainer',
  'placeholder',
  'image',
  'discount',
] as const)

const StyledImage = styled(Image)({})

const ProductListItemByNP = (props: ProductListItemProps) => {
  const {
    sku,
    subTitle,
    topLeft,
    topRight,
    bottomLeft,
    bottomRight,
    small_image,
    name,
    systembrand,
    price_range,
    children,
    imageOnly = false,
    loading,
    sizes,
    dontReportWronglySizedImages,
    aspectRatio = [4, 3],
    // titleComponent = 'h2',
    sx = [],
    onClick,
  } = props

  const router = useRouter()
  const pathName = usePathname()

  let buttonText = 'Gå til filter'

  if (pathName.includes('select-product')) {
    buttonText = 'Legg til nytt Filter'
  }

  const handleClick: any = useEventCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => onClick?.(e, props),
  )

  const productLink = useProductLink(props)
  const discount = Math.floor(price_range.minimum_price.discount?.percent_off ?? 0)

  const formatter = useNumberFormat({ style: 'percent', maximumFractionDigits: 1 })
  return (
    <Box
      onClick={(e: any) => {
        // router.push(productLink)
        onClick ? onClick?.(e, props) : router.push(productLink)
      }}
      sx={[
        {
          position: 'relative',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      className={classes.root && 'product-small product'}
      // onClick={onClick ? handleClick : undefined}
    >
      <Box
        sx={(theme) => ({
          display: 'grid',
          bgcolor: 'background.image',
          ...breakpointVal(
            'borderRadius',
            theme.shape.borderRadius * 2,
            theme.shape.borderRadius * 3,
            theme.breakpoints.values,
          ),
          overflow: 'hidden',
          padding: responsiveVal(8, 12),
          '& > picture': {
            gridArea: `1 / 1 / 3 / 3`,
            margin: `calc(${responsiveVal(8, 12)} * -1)`,
          },
        })}
        className={classes.imageContainer}
      >
        {small_image ? (
          <StyledImage
            layout='fill'
            width={1}
            height={1}
            sizes={sizes}
            dontReportWronglySizedImages={dontReportWronglySizedImages}
            src={small_image.url ?? ''}
            alt={small_image.label ?? ''}
            className={classes.image}
            loading={loading}
            sx={{ objectFit: 'contain', aspectRatio: `${aspectRatio[0] / aspectRatio[1]}` }}
            loader={({ src, width }) => {
              let w = width

              if (width <= 828) {
                w = 1200
              }
              return `/_next/image?url=${encodeURIComponent(src)}&w=${w}&q=${100}`
            }}
          />
        ) : (
          <Box
            sx={{
              gridArea: `1 / 1 / 3 / 3`,
              typography: 'caption',
              display: 'flex',
              textAlign: 'center',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'background.default',
              userSelect: 'none',
            }}
            className={`${classes.placeholder} ${classes.image}`}
          >
            <Trans id='No Image' />
          </Box>
        )}

        {!imageOnly && (
          <>
            <Box
              sx={{
                gridArea: `1 / 1 / 2 / 2`,
                zIndex: 1,
              }}
              className={classes.topLeft}
            >
              {discount > 0 && (
                <Box
                  className={classes.discount}
                  sx={{
                    typography: 'caption',
                    bgcolor: 'text.primary',
                    fontWeight: 'fontWeightBold',
                    border: 1,
                    borderColor: 'divider',
                    padding: '0px 6px',
                    color: 'background.default',
                    display: 'inline-block',
                  }}
                >
                  {formatter.format(discount / -100)}
                </Box>
              )}
              {topLeft}
            </Box>
            <Box
              sx={{
                justifySelf: 'end',
                textAlign: 'right',
                gridArea: `1 / 2 / 2 / 3`,
                zIndex: 1,
              }}
              className={classes.topLeft}
            >
              {topRight}
            </Box>
            <Box
              sx={{
                alignSelf: 'flex-end',
                gridArea: `2 / 1 / 3 / 2`,
                zIndex: 1,
              }}
              className={classes.bottomLeft}
            >
              {bottomLeft}
            </Box>
            <Box
              sx={{
                textAlign: 'right',
                alignSelf: 'flex-end',
                gridArea: `2 / 2 / 3 / 3`,
                zIndex: 1,
                justifySelf: 'end',
              }}
              className={classes.bottomRight}
            >
              {bottomRight}
            </Box>
          </>
        )}
      </Box>

      {!imageOnly && (
        <>
          <Box
            className={classes.titleContainer && 'box-text-products'}
            sx={(theme) => ({
              display: 'grid',
              alignItems: 'baseline',
              marginTop: theme.spacings.xs,
              marginBottom: '55px',
              columnGap: 1,
              gridTemplateAreas: {
                xs: `"title title" "product-cat product-cat" "product-sku product-sku" "readmore readmore"`,
                md: `"title subtitle price" "product-cat product-cat product-cat" "product-sku product-sku product-sku"`,
              },
              // gridTemplateColumns: { xs: 'unset', md: '1fr 1fr' },
              // gridTemplateRows: { xs: '1fr 1fr 1fr 1fr', md: '1fr 1fr 1fr 1fr' },
              justifyContent: 'space-between',
            })}
          >
            <Stack>
              <Typography
                variant='subtitle1'
                sx={{
                  display: 'inline',
                  color: 'text.primary',
                  overflowWrap: 'break-word',
                  wordBreak: 'break-word',
                  maxWidth: '100%',
                  gridArea: 'title',
                  fontWeight: 'fontWeightBold',
                  fontVariationSettings: "'wght' 700",
                }}
                className={classes.title && 'product-title'}
              >
                {name}
              </Typography>
              {systembrand && (
                <div className='product-cat' style={{ gridArea: 'product-cat' }}>
                  {systembrand}
                </div>
              )}
              <Box sx={{ gridArea: 'product-sku' }}>
                <ProductSku sku={sku ?? ''} fontSize={[16, 14]} />
              </Box>
              <Box sx={{ gridArea: 'subtitle' }} className={classes.subtitle}>
                {subTitle}
              </Box>
            </Stack>

            {/* <ProductListPrice
              {...price_range.minimum_price}
              sx={{
                gridArea: 'price',
                textAlign: 'right',
                justifySelf: { sm: 'flex-end' },
              }}
            /> */}
            <Box sx={{ position: 'absolute', bottom: '24px', display: 'block', width: '86%' }}>
              <Button
                size='large'
                color='secondary'
                className='product-item-link'
                fullWidth={true}
                endIcon={<IconSvg src={iconChevronRight} />}
                sx={() => ({ gridArea: 'readmore', lineHeight: 1 })}
                onClick={onClick ? handleClick : () => {}}
              >
                {buttonText}
              </Button>
            </Box>
          </Box>
          {children}
        </>
      )}
    </Box>
  )
}

function ProductListItemPlugin(props: PluginProps<ProductListItemProps>) {
  const { Prev, ...rest } = props
  return <ProductListItemByNP {...rest} />
}

export const Plugin = ProductListItemPlugin
