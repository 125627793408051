import { Typography, styled } from '@mui/material'
import React from 'react'
import { extendableComponent } from '@graphcommerce/next-ui/Styles/extendableComponent'
import { NavigationNode } from '@graphcommerce/next-ui/Navigation/hooks/useNavigation'
import { mouseEventPref } from '@graphcommerce/next-ui/Navigation/components/NavigationItem'
import { NavigationItemByNP } from './NavigationItemByNP'
import { groupBy } from 'lodash'

const NavigationUList = styled('ul')({
  display: 'block',
  position: 'absolute',
  left: '-10000px',
  top: '-10000px',
  '&.selected': {
    display: 'contents',
  },
  '&.group-multi': {
    display: 'block',
    position: 'relative',
    top: 'unset',
    left: 'unset',
  },
})

type NavigationItemsProps = {
  parentPath?: string
  items: NavigationNode[]
  selected?: boolean
  onLoadProducts?: any
  currentItemLabel?: string
} & mouseEventPref & {
    selectedLevel?: number
  }

type OwnerState = {
  column: number
  selected: boolean
}

const name = 'NavigationList'
const parts = ['root'] as const
const { withState } = extendableComponent<OwnerState, typeof name, typeof parts>(name, parts)
const GROUPALL = '_'

export const NavigationListByNP = React.memo<NavigationItemsProps>((props) => {
  const {
    items,
    parentPath = '',
    selected = false,
    mouseEvent,
    onLoadProducts,
    selectedLevel,
    currentItemLabel,
  } = props

  const groupBySeries = (array) => {
    return groupBy(array, (obj) => obj.cat_series || GROUPALL)
  }

  // useEffect(() => {
  //   setListing({ [GROUPALL]: items })
  //   const isNotType = items.filter((itemType) => itemType.type).length > 0
  //   if (!isNotType) {
  //     setListing(groupBySeries(items))
  //   }
  //   updateLoading(false)
  // }, [items])

  const renderMenuItemWrap = (itemsGroup: Array<any>, groupName: string) => {
    let out: any = null
    out =
      groupName != GROUPALL ? (
        itemsGroup.map((item, idx) => (
          <ul key={idx} className='navigation-items-group-item'>
            <NavigationItemByNP
              NavigationList={NavigationListByNP}
              key={item.id}
              {...item}
              parentPath={parentPath}
              idx={idx}
              first={idx === 0}
              last={idx === itemsGroup.length - 1}
              column={0}
              mouseEvent={mouseEvent}
              onLoadProducts={onLoadProducts}
            />
          </ul>
        ))
      ) : (
        <NavigationUList
          className={
            classes.root +
            (' menu-level-' +
              selectedLevel +
              ' group' +
              (Object.keys(listing).length > 1 ? '-multi' : '-single'))
          }
        >
          {itemsGroup.map((item, idx) => {
            return (
              <React.Fragment key={idx}>
                <NavigationItemByNP
                  currentItemLabel={currentItemLabel}
                  NavigationList={NavigationListByNP}
                  key={idx}
                  {...item}
                  parentPath={parentPath}
                  idx={idx}
                  first={idx === 0}
                  last={idx === itemsGroup.length - 1}
                  column={0}
                  mouseEvent={mouseEvent}
                  onLoadProducts={onLoadProducts}
                />
              </React.Fragment>
            )
          })}
        </NavigationUList>
      )

    return out
  }

  let listing: any = { [GROUPALL]: items }
  const isNotType = items.filter((itemType) => itemType.type).length > 0
  if (!isNotType) {
    listing = groupBySeries(items)
  }
  //updateLoading(false)

  const classes = withState({ column: 0, selected })
  const headingGroupLabel = () => (
    <ul className='item-group-label'>
      <li>
        <span>{currentItemLabel}</span>
      </li>
    </ul>
  )

  return (
    <>
      {Object.entries(listing).map(([group, itemsGroup], indexGroup) => (
        <React.Fragment key={indexGroup}>
          {group != GROUPALL && (
            <>
              {indexGroup === 0 && currentItemLabel && headingGroupLabel()}
              <ul className={'category-ul'}>
                <li>
                  <Typography
                    variant='h3'
                    sx={(theme) => ({
                      paddingTop: theme.spacing(2),
                      paddingLeft: theme.spacing(2),
                    })}
                  >
                    {group}
                  </Typography>
                </li>
              </ul>
            </>
          )}

          {itemsGroup instanceof Array && renderMenuItemWrap(itemsGroup, group)}
        </React.Fragment>
      ))}
    </>
  )
})

if (process.env.NODE_ENV !== 'production') {
  NavigationListByNP.displayName = 'NavigationList'
}
