import { useLazyQuery } from '@graphcommerce/graphql'
import { ProductListItems } from '@graphcommerce/magento-product'
import { ProductListDocument } from '@graphcommerce/magento-product/components/ProductList/ProductList.gql'
import { Box, Container, Fab } from '@mui/material'
import LoadingSpinner from '../LoadingSpinner'
import { useEffect, useState } from 'react'
import { IconSvg, iconChevronLeft, iconChevronRight } from '@graphcommerce/next-ui'
import { Trans } from '@lingui/react'
import { useRouter } from 'next/router'
import { CUSTOM_PARAM } from '../../../pages/account/select-product'

export type NavigationListingProductsProps = {
  slug: string
  onClose?: any
  items: any
  addFilterOnly?: boolean
}

export const NavigationListingProducts = (props: NavigationListingProductsProps) => {
  const { slug, onClose } = props
  const uid = slug.replace('-all', '')
  const [currentPage, setCurrentPage] = useState(1)

  const route = useRouter()
  const isFilterPage = route.asPath.includes('select-product')

  let products: any[] = []
  let totalPages = 1
  const variables = {
    filters: { category_uid: { in: [uid] } },
    currentPage: currentPage,
    // pageSize: 2,
  }
  const [productsQueryAction, productsQuery] = useLazyQuery(ProductListDocument)

  const handleClick = (_event, data) => {
    return route.push('/' + data?.url_key).then(onClose())
  }

  useEffect(() => {
    productsQueryAction({ variables: variables })
  }, [currentPage])

  if (!productsQuery.loading) {
    products = [
      ...(productsQuery.data?.products?.items?.map((product) => {
        let newUrlKey = product?.url_key
        if (product && props[CUSTOM_PARAM]) {
          newUrlKey = newUrlKey ? 'add-filter/' + newUrlKey + '?' + CUSTOM_PARAM : null
        }
        return {
          ...product,
          url_key: newUrlKey,
        }
      }) ?? []),
    ]
    totalPages = productsQuery.data?.products?.page_info?.total_pages ?? 1
  }

  // TODO: [Begin] Remove unused logic
  // const findItemWithNameAtLevel = (arr, level, name) => {
  //   return arr.reduce(function (result, obj) {
  //     if (obj.id === name) {
  //       return obj
  //     } else if (Array.isArray(obj.childItems) && level > 1) {
  //       const foundItem = findItemWithNameAtLevel(obj.childItems, level - 1, name)
  //       return foundItem || result
  //     } else {
  //       return result
  //     }
  //   }, null)
  // }

  // useEffect(() => {
  //   if (products.length == 1) {
  //     const cat = findItemWithNameAtLevel(props.items, 4, slug)
  //     if (cat && cat.href) {
  //       route.push(cat.href)
  //       onClose()
  //     }
  //   }
  // }, [products])
  // [End] Remove unused logic

  if (productsQuery.loading) return <LoadingSpinner />

  return (
    <>
      {products.length > 0 ? (
        <>
          <Container
            sx={{
              width: {
                md: '600px',
              },
            }}
            maxWidth={false}
          >
            <ProductListItems
              title={`Products`}
              items={products}
              loadingEager={1}
              onClick={handleClick}
            />
            <Box
              sx={(theme) => ({
                padding: theme.spacing(3),
              })}
            >
              <Fab
                color='primary'
                size='medium'
                onClick={() => setCurrentPage(currentPage - 1)}
                sx={{ float: 'left', display: currentPage > 1 ? 'block' : 'none' }}
              >
                <IconSvg src={iconChevronLeft} />
              </Fab>
              <Fab
                color='primary'
                size='medium'
                onClick={() => setCurrentPage(currentPage + 1)}
                sx={{ float: 'right', display: currentPage < totalPages ? 'block' : 'none' }}
              >
                <IconSvg src={iconChevronRight} sx={{ fontSize: '24px' }} />
              </Fab>
            </Box>
          </Container>
        </>
      ) : (
        <Box sx={(theme) => ({ textAlign: 'center', padding: theme.spacing(2) })}>
          <Trans id='no products'>no products</Trans>
        </Box>
      )}
    </>
  )
}
