import { useMotionValueValue, useMotionSelector, dvw } from '@graphcommerce/framer-utils'
import { i18n } from '@lingui/core'
import { useTheme, Box, Fab, SxProps, Theme, useEventCallback, styled } from '@mui/material'
import { m } from 'framer-motion'
import React, { useEffect, useState, useRef } from 'react'
import type { LiteralUnion } from 'type-fest'
import { IconSvg, useIconSvgSize } from '@graphcommerce/next-ui'
import { LayoutHeaderContent } from '@graphcommerce/next-ui/Layout/components/LayoutHeaderContent'
import { LayoutTitle } from '@graphcommerce/next-ui/Layout/components/LayoutTitle'
import { OverlaySsr } from '@graphcommerce/next-ui/Overlay/components/OverlaySsr'
import { extendableComponent } from '@graphcommerce/next-ui/Styles/extendableComponent'
import { useFabSize } from '@graphcommerce/next-ui/Theme'
// import { iconClose } from '@graphcommerce/next-ui/icons'
import IconClose from '../../Common/Icons/Close/close-icon.svg'
import { useNavigation } from '@graphcommerce/next-ui/Navigation/hooks/useNavigation'
import { mouseEventPref } from '@graphcommerce/next-ui/Navigation/components/NavigationItem'
import { NavigationTitle } from '@graphcommerce/next-ui/Navigation/components/NavigationTitle'
import IconArrowLeft from '../../Common/Icons/ArrowLeft/arrow-left-icon.svg'
import { NavigationListByNP } from './NavigationListByNP'
import { NavigationListingProducts } from './NavigationListingProducts'
import { getMainCategoryUid } from '../../../utils/magentoMenuToNavigation'
import { LayoutOverlayHeaderV2 } from '../LayoutOverlayV2'
import { useRouter } from 'next/router'
import { usePrevPageRouter } from '@graphcommerce/framer-next-pages'

type LayoutOverlayVariant = 'left' | 'bottom' | 'right'
type LayoutOverlaySize = 'floating' | 'minimal' | 'full'
type LayoutOverlayAlign = 'start' | 'end' | 'center' | 'stretch'
type ItemPadding = LiteralUnion<keyof Theme['spacings'], string | number>

type NavigationOverlayProps = {
  sx?: SxProps<Theme>
  stretchColumns?: boolean
  variantSm: LayoutOverlayVariant
  variantMd: LayoutOverlayVariant
  sizeSm?: LayoutOverlaySize
  sizeMd?: LayoutOverlaySize
  justifySm?: LayoutOverlayAlign
  justifyMd?: LayoutOverlayAlign
  itemWidthSm?: string
  itemWidthMd?: string
  itemPadding?: ItemPadding
  isParent?: boolean
  onCloseParent?: () => void
} & mouseEventPref

const MotionDiv = styled(m.div)({})

const componentName = 'Navigation'
const parts = ['root', 'navigation', 'header', 'column'] as const
const { classes } = extendableComponent(componentName, parts)

export const NavigationOverlayByNP = React.memo(
  (props: NavigationOverlayProps & { classAttr?: string }) => {
    const {
      sx,
      stretchColumns,
      variantMd,
      variantSm,
      justifyMd,
      justifySm,
      sizeMd,
      sizeSm,
      itemWidthSm = dvw(100),
      itemWidthMd,
      mouseEvent,
      itemPadding = 'md',
      isParent,
      classAttr,
      onCloseParent,
    } = props
    const { selection, items, animating, closing, serverRenderDepth, animationDuration } =
      useNavigation()

    const menuItemsRef = useRef<any>()
    const prevRouter = usePrevPageRouter()

    const fabMarginY = `calc((${useFabSize('responsive')} - ${useIconSvgSize('large')}) * -0.5)`
    const itemPad = useTheme().spacings[itemPadding] ?? itemPadding

    //check is showing preoducts
    const [loadProducts, setloadProducts] = useState('')

    const router = useRouter()
    let isSelectProductPage = false
    if (router.asPath.includes('select-product')) {
      isSelectProductPage = true
    }

    const overlayTitle = isSelectProductPage ? (
      loadProducts ? (
        'Velg kvalitet'
      ) : (
        'Velg filter'
      )
    ) : (
      <NavigationTitle />
    )

    const selectedLevel = useMotionValueValue(selection, (s) => (s === false ? -1 : s.length))
    const selectionValue = useMotionValueValue(selection, (s) => (s ? s.join('') : s))
    const activeAndNotClosing = useMotionSelector([selection, closing], ([s, c]) =>
      c ? false : s !== false,
    )

    //scroll to top when reload
    const scrollTopHandle = () => {
      document.getElementById('top-nav-main')?.scrollIntoView()
    }

    const handleOnBack = useEventCallback(() => {
      const current = selection.get()

      if (isSelectProductPage && current !== false && current.length < 2) {
        return router.push('/account')
      }

      //handle product category menu be opened from from homepage: Finn ditt filter
      const hash = router.asPath
      if (hash.includes('single-filter')) {
        //click from All button, shouch back to previous step
        const arr: any = hash.split('::')
        const allMenuId = 'OTg='
        if (arr[1] === allMenuId && selectedLevel >= 2) {
          //const current2 = selection.get()
          setloadProducts('')
          selection.set(current !== false ? (loadProducts ? current : current.slice(0, -1)) : false)
          return
        } else {
          closing.set(true)
          setloadProducts('')
          return router.back()
        }
      }

      // if (matchMedia.down('md')) {
      //const current = selection.get()
      selection.set(current !== false ? (loadProducts ? current : current.slice(0, -1)) : false)
      //}
      setloadProducts('')
    })

    useEffect(() => {
      loadProducts && scrollTopHandle()
    }, [loadProducts])

    const filterPage = getMainCategoryUid(items)

    useEffect(() => {
      if (filterPage) {
        selection.set([filterPage])
      }
    }, [filterPage])

    useEffect(() => {
      if (filterPage) {
        selection.set([filterPage])
      }
    }, [router])

    useEffect(() => {
      animating.set(true)
      activeAndNotClosing && scrollTopHandle()
    }, [activeAndNotClosing, animating])

    const afterClose = useEventCallback(() => {
      onCloseParent && onCloseParent()
      if (!closing.get()) return
      setTimeout(() => {
        closing.set(false)
        if (router.asPath.includes('select-product') && !loadProducts) {
          //this route auto redirect to account page before navigate to product detail page
          if (!prevRouter) {
            //return router.push('/account')
          }
          //return router.back()
        }
      }, animationDuration * 1000)
      selection.set(false)
    })

    const onLoadProducts = (event, value: string) => {
      setloadProducts(value)
    }

    const handleClose = useEventCallback(() => {
      closing.set(true)
      setloadProducts('')

      if (router.asPath.includes('select-product')) {
        //this route auto redirect to account page before navigate to product detail page
        if (!prevRouter) {
          //return router.push('/account')
        }
        //return router.back()
      }
      //handle product category menu be opened from from homepage: Finn ditt filter
      // if (router.asPath.includes('#main-menu')) {
      //   return router.push('/')
      // }
    })

    if (selectedLevel === -1 && serverRenderDepth <= 0) return null

    if (selectedLevel > 0 && isParent) return null //show child only
    if (selectedLevel < 1 && !isParent) return null //show parent only

    /**
     * https://netpower.atlassian.net/browse/FL-429
     * back button hide on first level
     */
    const hideBackBtnOnMenu =
      selectionValue === 'OTg=' && selectedLevel === 1 && router.asPath === '/'

    //@ts-ignore
    const childItems = items && items.length > 0 ? items[0]?.childItems : ''

    const currentItemLabel =
      selectionValue && childItems?.find((item) => selectionValue.includes(item.id))?.name

    return (
      <OverlaySsr
        className={classes.root + ` main-menu current-level-${selectedLevel} ` + classAttr}
        active={activeAndNotClosing}
        safeToRemove={afterClose}
        onClosed={handleClose}
        variantSm={variantSm}
        sizeSm={sizeSm}
        justifySm={justifySm}
        variantMd={variantMd}
        sizeMd={sizeMd}
        justifyMd={justifyMd}
        widthMd={false}
        widthSm={false}
        overlayPaneProps={{
          layout: true,
          layoutDependency: selectionValue,
          initial: false,
          onLayoutAnimationStart: () => {
            animating.set(true)
          },
          onLayoutAnimationComplete: () => {
            animating.set(false)
          },
        }}
        sx={{
          zIndex: 'drawer',
          '& .LayoutOverlayBase-overlayPane': {
            minWidth: itemWidthMd,
            boxShadow: 'none',
          },
          '& .LayoutOverlayBase-background': {
            borderTopRightRadius: '0.75rem',
            borderBottomRightRadius: '0.75rem',
            paddingTop: '8px',
          },
        }}
      >
        {/*header fixed*/}
        <LayoutOverlayHeaderV2
          primary={
            <Fab
              color='inherit'
              onClick={handleClose}
              sx={{ boxShadow: 'none', my: fabMarginY }}
              size='responsive'
              aria-label={i18n._(/* i18n */ 'Close')}
            >
              <IconSvg sx={{ fontSize: '24px' }} src={IconClose} size='inherit' aria-hidden />
            </Fab>
          }
          secondary={
            hideBackBtnOnMenu ? null : (
              <Fab
                className={'back-nav-overlay'}
                color='inherit'
                onClick={() => handleOnBack()}
                sx={{ boxShadow: 'none', my: fabMarginY }}
                size='responsive'
                aria-label={i18n._(/* i18n */ 'Close')}
              >
                <IconSvg src={IconArrowLeft} sx={{ fontSize: '24px' }} aria-hidden size='inherit' />
              </Fab>
            )
          }
          // closeBtn={true}
          // onClose={handleClose}
          // backBtn={true}
          // onBack={handleOnBack}
        >
          <LayoutTitle size='small' component='span'>
            {overlayTitle}
          </LayoutTitle>
        </LayoutOverlayHeaderV2>
        {/*header fixed*/}
        <MotionDiv layout layoutDependency={selectionValue} sx={{ display: 'grid' }}>
          <Box
            id='top-nav-main'
            className={classes.header && 'top-nav-main top-nav-main-sticky'}
            sx={(theme) => ({
              top: 0,
              position: 'sticky',
              height: { xs: '52px', md: theme.appShell.appBarHeightMd },
              zIndex: 1,
              '& .LayoutHeaderContent-bg, .LayoutHeaderContent-content': {
                height: {
                  xs: '52px',
                  md: '66px',
                },
              },
              '& .LayoutTitle-root .MuiTypography-root': {
                fontSize: '18px',
                fontVariationSettings: "'wght' 660",
              },
            })}
          >
            <LayoutHeaderContent
              floatingMd={false}
              floatingSm={false}
              switchPoint={0}
              layout='position'
              layoutDependency={selectionValue}
              // left={
              //   selectedLevel > 0 && (
              //     <Fab
              //       color='inherit'
              //       onClick={handleOnBack}
              //       sx={{ boxShadow: 'none', my: fabMarginY }}
              //       size='responsive'
              //       aria-label={i18n._(/* i18n */ 'Back')}
              //     >
              //       <IconSvg src={IconArrowLeft} size='large' aria-hidden />
              //     </Fab>
              //   )
              // }
              sx={(theme) => ({
                '& .LayoutHeaderContent-content': {
                  paddingTop: theme.spacing(1.5),
                  '& .LayoutHeaderContent-center': {
                    paddingLeft: '12px',
                    paddingRight: '12px',
                    '& .LayoutTitle-root': {
                      marginTop: theme.spacing(2),
                      marginBottom: theme.spacing(2),
                    },
                  },
                },
              })}
            >
              <LayoutTitle size='medium' component='span'>
                {overlayTitle}
              </LayoutTitle>
            </LayoutHeaderContent>
          </Box>
        </MotionDiv>
        <MotionDiv layout='position' layoutDependency={selectionValue} sx={{ display: 'grid' }}>
          <Box
            ref={menuItemsRef}
            className={`menu-wrap menu-wrap-level-${selectedLevel}`}
            sx={[
              (theme) => ({
                display: 'grid',
                alignItems: !stretchColumns ? 'start' : undefined,
                justifyContent: loadProducts ? 'center' : 'end',
                [theme.breakpoints.down('md')]: {
                  width:
                    sizeSm !== 'floating'
                      ? itemWidthSm
                      : `calc(${itemWidthSm} - (${theme.page.horizontal} * 2))`,
                  minWidth: 200,
                  overflow: 'hidden',
                  scrollSnapType: 'x mandatory',
                  '& .NavigationItem-item': {
                    width:
                      sizeSm !== 'floating'
                        ? `calc(${itemWidthSm} - (${itemPad} * 2))`
                        : `calc(${itemWidthSm} - (${itemPad} * 2) - (${theme.page.horizontal} * 2))`,
                    minWidth: `calc(200px - (${itemPad} * 2))`,
                  },
                },
                [theme.breakpoints.up('md')]: {
                  '& .NavigationItem-item': {
                    width: itemWidthMd || 'stretch',
                  },
                },
              }),
            ]}
          >
            {loadProducts ? (
              <NavigationListingProducts
                slug={loadProducts}
                items={items}
                onClose={handleClose}
                addFilterOnly={!!filterPage}
              />
            ) : (
              <Box
                className={classes.navigation + ' navigation-container'}
                sx={[
                  {
                    py: itemPad,
                    display: 'grid',
                    gridAutoFlow: 'column',
                    scrollSnapAlign: 'end', //do not change
                    '& > ul > li > a, & > ul > li > [role=button]': {
                      '& span': {
                        typography: 'h2',
                      },
                      // '& svg': { display: 'none' },
                    },
                    '& .Navigation-column': {},
                    '& .NavigationItem-item': {
                      mx: itemPad,
                      whiteSpace: 'nowrap',
                    },
                    '& .NavigationItem-item.first': {
                      // mt: paddingMd,
                    },
                    '& .Navigation-column:first-of-type': {
                      boxShadow: 'none',
                    },
                  },
                  ...(Array.isArray(sx) ? sx : [sx]),
                ]}
              >
                {selectedLevel >= 0 && (
                  <Box
                    sx={(theme) => ({
                      gridArea: '1 / 1 / 999 / 2',
                      boxShadow: `inset 1px 0 ${theme.palette.divider}`,
                    })}
                    className={classes.column}
                  />
                )}
                {selectedLevel >= 1 && (
                  <Box
                    sx={(theme) => ({
                      gridArea: '1 / 2 / 999 / 3',
                      boxShadow: `inset 1px 0 ${theme.palette.divider}`,
                    })}
                    className={classes.column}
                  />
                )}
                {selectedLevel >= 2 && (
                  <Box
                    sx={(theme) => ({
                      gridArea: '1 / 3 / 999 / 4',
                      boxShadow: `inset 1px 0 ${theme.palette.divider}`,
                    })}
                    className={classes.column}
                  />
                )}
                {selectedLevel >= 3 && (
                  <Box
                    sx={(theme) => ({
                      gridArea: '1 / 4 / 999 / 5',
                      boxShadow: `inset 1px 0 ${theme.palette.divider}`,
                    })}
                    className={classes.column}
                  />
                )}
                <NavigationListByNP
                  onLoadProducts={onLoadProducts}
                  items={items}
                  selected
                  mouseEvent={mouseEvent}
                  currentItemLabel={currentItemLabel}
                />
              </Box>
            )}
          </Box>
        </MotionDiv>
      </OverlaySsr>
    )
  },
)

if (process.env.NODE_ENV !== 'production') {
  NavigationOverlayByNP.displayName = 'NavigationOverlay'
}
