import { CircularProgress } from '@mui/material'
import { Trans } from '@lingui/react'
import { FullPageMessage } from '@graphcommerce/next-ui'
import React from 'react'

interface LoadingProps {
  text?: string
}
const LoadingSpinner = ({ text = 'Loading' }: LoadingProps) => {
  return (
    <FullPageMessage icon={<CircularProgress />} title={<Trans id={text} />}>
      <Trans id='This may take a second' />
    </FullPageMessage>
  )
}

export default LoadingSpinner
