import { ProductItemsGridProps } from '@graphcommerce/magento-product/components/ProductListItems/ProductListItemsBase'
import { PluginProps } from '@graphcommerce/next-config'
import { RenderType } from '@graphcommerce/next-ui/RenderType/RenderType'
import { responsiveVal } from '@graphcommerce/next-ui/Styles/responsiveVal'
import Box from '@mui/material/Box/Box'

export const component = 'ProductListItemsBase'
export const exported =
  '@graphcommerce/magento-product/components/ProductListItems/ProductListItemsBase'

const ProductListItemsBaseByNP = (props: ProductItemsGridProps) => {
  const {
    items,
    sx = [],
    renderers,
    loadingEager = 0,
    size = 'normal',
    titleComponent,
    onClick,
  } = props

  return (
    <Box
      className='listing-products products'
      sx={[
        (theme) => ({
          display: 'grid',
          gridGap: '0px',
          '& .ProductListItem-root': {
            boxSizing: 'content-box',
            padding: theme.spacing(2),
          },
          [theme.breakpoints.down('md')]: {
            '& .ProductListItem-root:nth-of-type(2n)': {
              borderRightWidth: '0px',
            },
          },
          [theme.breakpoints.between('md', 'lg')]: {
            '& .ProductListItem-root:nth-of-type(3n)': {
              borderRightWidth: '0px',
            },
          },
          [theme.breakpoints.up('lg')]: {
            '& .ProductListItem-root:nth-of-type(4n)': {
              borderRightWidth: '0px',
            },
          },
        }),
        size === 'small' && {
          gridTemplateColumns: `repeat(auto-fill, minmax(${responsiveVal(150, 280)}, 1fr))`,
        },
        size === 'normal' && {
          gridTemplateColumns: { xs: `repeat(2, 1fr)`, md: `repeat(3, 1fr)`, lg: `repeat(3, 1fr)` },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {items?.map((item, idx) =>
        item ? (
          <RenderType
            key={item.uid ?? ''}
            renderer={renderers}
            sizes={
              size === 'small'
                ? { 0: '100vw', 354: '50vw', 675: '30vw', 1255: '23vw', 1500: '337px' }
                : { 0: '100vw', 367: '48vw', 994: '30vw', 1590: '23vw', 1920: '443px' }
            }
            {...item}
            loading={loadingEager > idx ? 'eager' : 'lazy'}
            titleComponent={titleComponent}
            onClick={onClick}
            noReport
          />
        ) : null,
      )}
    </Box>
  )
}

function ProductListItemsBasePlugin(props: PluginProps<ProductItemsGridProps>) {
  const { Prev, ...rest } = props
  return <ProductListItemsBaseByNP {...rest} />
}

export const Plugin = ProductListItemsBasePlugin
