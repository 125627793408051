/* eslint-disable @typescript-eslint/no-use-before-define */
import { useMotionValueValue } from '@graphcommerce/framer-utils'
import { alpha, Box, ListItemButton, styled, useEventCallback } from '@mui/material'
import React from 'react'
import { IconSvg } from '@graphcommerce/next-ui'
import { extendableComponent } from '@graphcommerce/next-ui/Styles/extendableComponent'
import { NextLink } from '@graphcommerce/next-ui/Theme'
import { useMatchMedia } from '@graphcommerce/next-ui/hooks'
import { iconChevronRight } from '@graphcommerce/next-ui/icons'
import {
  isNavigationButton,
  isNavigationComponent,
  isNavigationHref,
  NavigationNode,
  useNavigation,
} from '@graphcommerce/next-ui/Navigation/hooks/useNavigation'
import type { NavigationListByNP } from './NavigationListByNP'
import { useRouter } from 'next/router'

type OwnerState = {
  first?: boolean
  last?: boolean
  column: number
}

type NavigationItemProps = NavigationNode & {
  parentPath: string
  idx: number
  NavigationList: typeof NavigationListByNP
  onLoadProducts: any
  product_count?: number
  currentItemLabel?: string
} & OwnerState &
  mouseEventPref

export type mouseEventPref = {
  mouseEvent: 'click' | 'hover'
}

const componentName = 'NavigationItem'
const parts = ['li', 'ul', 'item'] as const

const { withState } = extendableComponent<OwnerState, typeof componentName, typeof parts>(
  componentName,
  parts,
)

const NavigationLI = styled('li')({ display: 'contents' })

export const NavigationItemByNP = React.memo<NavigationItemProps>((props) => {
  const { id, parentPath, idx, first, last, NavigationList, mouseEvent, currentItemLabel } = props
  const { selection, hideRootOnNavigate, closing, animating, serverRenderDepth } = useNavigation()
  const router = useRouter()

  const row = idx + 1

  const itemPath = [...(parentPath ? parentPath.split(',') : []), id]

  const isSelected = useMotionValueValue(
    selection,
    (s) => s !== false && s.slice(0, itemPath.length).join('/') === itemPath.join('/'),
  )
  const hidingRoot = useMotionValueValue(
    selection,
    (s) => s === false || (hideRootOnNavigate && s.length > 0),
  )

  const tabIndex = useMotionValueValue(selection, (s) =>
    s !== false && s.join(',').includes(parentPath) ? undefined : -1,
  )

  const hideItem = hidingRoot && itemPath.length === 1

  const column = hidingRoot ? itemPath.length - 1 : itemPath.length
  const classes = withState({ first, last, column: itemPath.length })

  const onCloseHandler: React.MouseEventHandler<HTMLAnchorElement> = useEventCallback(() => {
    if (!isNavigationHref(props)) return
    closing.set(true)
  })

  const matchMedia = useMatchMedia()

  if (isNavigationButton(props)) {
    const { childItems, name, href } = props
    const skipChildren = itemPath.length + 1 > serverRenderDepth && !isSelected && !!href

    return (
      <NavigationLI className={classes.li}>
        <ListItemButton
          component={NextLink}
          href={href}
          className={classes.item + ' list-item-btn'}
          role='button'
          sx={[
            (theme) => ({
              paddingTop: '15px',
              paddingBottom: '15px',
              gridRowStart: row,
              gridColumnStart: column,
              gap: theme.spacings.xxs,
              display: hideItem ? 'none' : 'flex',
              '&.Mui-disabled': {
                opacity: 1,
                background: alpha(theme.palette.action.hover, 0.025),
              },
            }),
            mouseEvent === 'hover'
              ? {
                  '&.Mui-disabled': {
                    cursor: 'pointer',
                    pointerEvents: 'auto',
                  },
                }
              : {},
          ]}
          disabled={isSelected}
          tabIndex={tabIndex}
          onClick={(e) => {
            e.preventDefault()
            if (!isSelected) {
              selection.set(itemPath)
            }
          }}
          onMouseMove={
            (itemPath.length > 1 || !hideRootOnNavigate) && mouseEvent === 'hover'
              ? (e) => {
                  if (!isSelected && !animating.get() && matchMedia.up('md')) {
                    e.preventDefault()
                    setTimeout(() => selection.set(itemPath), 0)
                  }
                }
              : undefined
          }
        >
          <Box
            component='span'
            sx={{
              whiteSpace: 'nowrap',
              overflowX: 'hidden',
              textOverflow: 'ellipsis',
              flexGrow: 1,
            }}
          >
            {name}
          </Box>
          <IconSvg src={iconChevronRight} sx={{ flexShrink: 0, fontSize: '24px' }} />
        </ListItemButton>
        {!skipChildren && (
          <NavigationList
            items={childItems}
            selected={isSelected}
            parentPath={itemPath.join(',')}
            mouseEvent={mouseEvent}
            onLoadProducts={props.onLoadProducts}
            currentItemLabel={currentItemLabel}
          />
        )}
      </NavigationLI>
    )
  }

  if (isNavigationHref(props)) {
    const { id, name, href } = props
    const itemID = String(id)
    const isLastCat = props.product_count && props.product_count != 1
    const itemHref = !isLastCat ? href : undefined

    //-menu-item-all
    let classesCustom = classes.li
    if (String(id).includes('menu-item-all')) {
      classesCustom += ' menu-item-all'

      if (router.asPath.includes('select-product')) {
        return null
      }
    }

    return (
      <NavigationLI sx={[hideItem && { display: 'none' }]} className={classesCustom}>
        <ListItemButton
          component={NextLink}
          // prefetch={false}
          href={itemHref}
          role='button'
          className={classes.item + ` menu-item-link`}
          sx={(theme) => ({
            gridRowStart: row,
            gridColumnStart: column,
            gap: theme.spacings.xxs,
            paddingTop: '15px',
            paddingBottom: '15px',
          })}
          tabIndex={tabIndex}
          onClick={(event) => {
            if (isLastCat) {
              event.preventDefault()
              props.onLoadProducts(event, itemID)
            } else if (router.asPath.includes('select-product')) {
              //[FL-346]: Fix product overlay behind another overlay
              event.preventDefault()
              return router.replace(itemHref as string).then(() => router.reload())
            } else {
              onCloseHandler(event)
            }
          }}
        >
          <Box
            component='span'
            sx={{
              whiteSpace: 'nowrap',
              overflowX: 'hidden',
              textOverflow: 'ellipsis',
              flexGrow: 1,
            }}
          >
            {name}
          </Box>
          <IconSvg src={iconChevronRight} sx={{ flexShrink: 0, fontSize: '24px' }} />
        </ListItemButton>
      </NavigationLI>
    )
  }

  if (isNavigationComponent(props)) {
    const { component } = props
    return (
      <NavigationLI sx={[hideItem && { display: 'none' }]} className={classes.li}>
        <Box sx={{ gridRowStart: row, gridColumnStart: column }} className={classes.item}>
          {component}
        </Box>
      </NavigationLI>
    )
  }

  return null
})

if (process.env.NODE_ENV !== 'production') {
  NavigationItemByNP.displayName = 'NavigationItem'
}
