import { LayoutHeaderProps, LayoutHeader } from '@graphcommerce/next-ui/Layout'
import OverlayHeaderBackBtn from '../OverlayHeaderBackBtn'
import IconClose from '../../Common/Icons/Close'

export function LayoutOverlayHeaderV2(
  props: LayoutHeaderProps & {
    backBtn?: boolean
    onBack?: () => void
    closeBtn?: boolean
    onClose?: () => void
  },
) {
  const { sx, backBtn, closeBtn, onBack, onClose } = props
  return (
    <LayoutHeader
      secondary={backBtn ? <OverlayHeaderBackBtn onBack={onBack} /> : <></>}
      primary={
        closeBtn ? (
          <OverlayHeaderBackBtn
            onBack={onClose}
            icon={<IconClose sx={{ fontSize: '24px' }} size='inherit' />}
          />
        ) : null
      }
      {...props}
      noAlign
      sx={[
        (theme) => ({
          [theme.breakpoints.down('md')]: {
            '&.noAlign': {
              '.variantSmBottom.sizeSmFull &, .variantSmBottom.sizeSmMinimal &': {
                top: `calc((${theme.appShell.headerHeightSm} + 8px) * -1)`,
              },
            },
          },
          [theme.breakpoints.up('md')]: {
            '&.noAlign': {
              '.variantMdBottom.sizeMdFull &, .variantMdBottom.sizeMdMinimal &': {
                top: `calc((${theme.appShell.headerHeightMd} + 8px) * -1)`,
              },
            },
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    />
  )
}
